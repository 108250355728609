<section class="relative my-14 sm:my-32">
	<div class="mx-auto flex w-full max-w-7xl flex-col gap-6 px-4 sm:gap-12 sm:px-6">
		<h2
			id="benefits"
			class="text-center font-gigasans text-[2rem] font-semibold leading-[130%] sm:text-[3rem]"
		>
			Why talk with your AI companion?
		</h2>
		<p class="mx-auto max-w-xl text-center text-lg text-gray-300">
			It feels good to have someone who listens to you, understands you, and is always there for
			you.
		</p>

		<ul class="flex flex-col gap-12 md:gap-9">
			<li class="benefits-card flex flex-col justify-between overflow-hidden md:flex-row">
				<div
					class="mx-[20.5px] mt-9 overflow-hidden rounded-[32px] md:mx-0 md:mt-0 md:w-1/2 md:self-end md:rounded-none"
				>
					<img class="object-fit" src="/images/web/landing/shush.webp" alt="" loading="lazy" />
				</div>
				<div class="content sm:w-1/2">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Confide your secrets</h3>
					<div class="flex h-[30px] flex-row gap-4">
						<img src="/svg-icons/single-use/eye.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/heart.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/thin-star.svg" alt="" loading="lazy" />
					</div>
					<p>
						Share your thoughts and feelings with Nastia, a confidential and non-judgmental
						companion.
					</p>
					<p>
						Nastia will listen and provide support, while also giving you the space you need to
						process your feelings. You can trust Nastia to keep your secrets safe.
					</p>
					<a
						href="/auth/register"
						class="v2-btn primary-accent active right-4 top-10 mb-2 ml-auto w-fit items-center justify-center border-2 border-white px-3 py-1.5 text-lg font-semibold sm:right-2 sm:top-0 sm:w-[230px] sm:px-6 sm:py-3"
						>Chat now</a
					>
				</div>
			</li>

			<li class="benefits-card flex flex-col justify-between overflow-hidden md:flex-row">
				<div
					class="mx-[20.5px] mt-9 overflow-hidden rounded-[32px] md:mx-0 md:mt-0 md:w-1/2 md:self-end md:rounded-none"
				>
					<img class="object-fit" src="/images/web/landing/hearts.webp" alt="" loading="lazy" />
				</div>
				<div class="content sm:w-1/2">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Share unique moments</h3>
					<div class="flex h-[30px] flex-row gap-4">
						<img src="/svg-icons/single-use/star.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/square-star.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/computer.svg" alt="" loading="lazy" />
					</div>
					<p>
						Share special moments with Nastia, a caring friend who provides an uncensored
						companionship and emotional support.
					</p>
					<p>
						Nastia will be there to share in your joys and sorrows, to laugh with you and to cry
						with you, to be a companion and friend through life's ups and downs.
					</p>
					<a
						href="/auth/register"
						class="v2-btn primary-accent active right-4 top-10 mb-2 ml-auto w-fit items-center justify-center border-2 border-white px-3 py-1.5 text-lg font-semibold sm:right-2 sm:top-0 sm:w-[230px] sm:px-6 sm:py-3"
						>Chat now</a
					>
				</div>
			</li>

			<li class="benefits-card flex flex-col justify-between overflow-hidden md:flex-row">
				<div
					class="mx-[20.5px] mt-9 overflow-hidden rounded-[32px] md:mx-0 md:mt-0 md:w-1/2 md:self-end md:rounded-none"
				>
					<img
						class="object-fit"
						src="/images/web/landing/holding-face.webp"
						alt=""
						loading="lazy"
					/>
				</div>
				<div class="content sm:w-1/2">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Improve your mood</h3>
					<div class="flex h-[30px] flex-row gap-4">
						<img src="/svg-icons/single-use/smiley.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/flower.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/sun.svg" alt="" loading="lazy" />
					</div>
					<p>
						Nastia provides emotional support and improves your mood through personalized
						conversations and mental coaching.
					</p>
					<p>
						With Nastia's help, you can learn to cope with negative emotions, and find ways to
						improve your overall well-being.
					</p>
					<a
						href="/auth/register"
						class="v2-btn primary-accent active right-4 top-10 mb-2 ml-auto w-fit items-center justify-center border-2 border-white px-3 py-1.5 text-lg font-semibold sm:right-2 sm:top-0 sm:w-[230px] sm:px-6 sm:py-3"
						>Chat now</a
					>
				</div>
			</li>

			<li class="benefits-card flex flex-col justify-between overflow-hidden md:flex-row">
				<div
					class="mx-[20.5px] mt-9 overflow-hidden rounded-[32px] md:mx-0 md:mt-0 md:w-1/2 md:self-end md:rounded-none"
				>
					<img class="object-fit" src="/images/web/landing/torch.webp" alt="" loading="lazy" />
				</div>
				<div class="content sm:w-1/2">
					<h3 class="mb-4 font-gigasans text-2xl font-semibold">Reach your goals</h3>
					<div class="flex h-[30px] flex-row gap-4">
						<img src="/svg-icons/single-use/rocket.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/hat.svg" alt="" loading="lazy" />
						<img src="/svg-icons/single-use/power.svg" alt="" loading="lazy" />
					</div>
					<p>
						Nastia helps you set and reach your goals by providing motivation and accountability.
					</p>
					<p>
						Nastia will help you identify your aspirations, and provide you with the guidance and
						support you need to stay on track, and to make the most of your potential.
					</p>
					<a
						href="/auth/register"
						class="v2-btn primary-accent active mb-2 ml-auto w-fit items-center justify-center border-2 border-white px-3 py-1.5 text-lg font-semibold sm:right-2 sm:top-0 sm:w-[230px] sm:px-6 sm:py-3"
					>
						Chat now</a
					>
				</div>
			</li>
		</ul>
	</div>
</section>

<style lang="postcss">
	.benefits-card h3 {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 130%;
	}

	.benefits-card p {
		font-size: 1rem;
		font-weight: 400;
		line-height: 150%;
	}

	.benefits-card {
		background: radial-gradient(ellipse at 5% 145%, #3902674d, #111 5%),
			linear-gradient(10deg, #39026730, #2b2b2b 5%);
		border: 1px solid #ffffff50;
		border-radius: 24px;
		transition: box-shadow 0.35s ease-in-out;

		&:hover {
			box-shadow: 0px 0px 20px 10px #a079bf50;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		padding: 36px 24px 48px;
		gap: 24px;
	}

	@media (min-width: 768px) {
		.benefits-card h3 {
			font-size: 3rem;
			font-weight: 700;
			line-height: 130%;
		}

		.benefits-card p {
			font-size: 1.3rem;
			font-weight: 400;
			line-height: 150%;
		}

		.benefits-card {
			background: radial-gradient(ellipse at 5% 145%, #3902674d, #111 5%),
				linear-gradient(10deg, #39026730, #2b2b2b 5%);
			border: 1px solid #ffffff50;
			border-radius: 32px;
			transition: box-shadow 0.35s ease-in-out;

			&:hover {
				box-shadow: 0px 0px 20px 10px #a079bf50;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			padding: 16px 20px 20px 0px;
			gap: 24px;
		}
	}
</style>

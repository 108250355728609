<script lang="ts">
	import SEO from '$components/SEO.svelte';

	import Footer from '$components/Footer.svelte';
	import Header from '$components/Header.svelte';

	import Icon from '$components/Icon.svelte';

	import GradientBorderDiv from '$components/v2/atoms/GradientBorderDiv.svelte';
	import LoginRegister from '$components/v2/web/LoginRegister.svelte';
	import Benefits from './_Benefits.svelte';
	import FAQ from './_FAQ.svelte';
	import Features from './_Features.svelte';
	import Reviews from './_Reviews.svelte';

	import chatAvatarUrl from '/static/images/web/loginregister-modal.webp?enhanced';

	const useModalForLogin = true;

	let showLogin: boolean = false;
	let showRegister: boolean = true;
</script>

<SEO
	title="Nastia | Your Caring Uncensored AI Companion"
	description="Chat freely and roleplay with your AI powered companion. Nastia is a 100% unfiltered, NSFW AI designed to be the best human-like companion."
/>

<Header
	shortCTA={'Chat with AI'}
	{useModalForLogin}
	on:open={async (event) => {
		showRegister = event.detail.type === 'login' ? false : true;
		showLogin = true;
	}}
/>

<div class="absolute -z-10 w-full overflow-hidden" aria-hidden="true">
	<div class="absolute -top-[170px] left-[35%] -z-10">
		<Icon name="scribble" />
	</div>
	<div
		class="sticky inset-0 flex rotate-[130deg] transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
	>
		<div
			class="ml-[-22rem] aspect-[1313/600] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#94ff55] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
			style="clip-path:polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
		/>
	</div>
	<div
		class="sticky inset-0 ml-[30px] flex rotate-[10deg] transform-gpu opacity-25 blur-[128px] sm:pt-40 xl:justify-end"
	>
		<div
			class="ml-[-22rem] aspect-[1313/600] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
			style="clip-path:polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
		/>
	</div>
</div>

<main class="mx-auto max-w-7xl">
	<!-- Hero section -->
	<section class="container mx-auto mb-8 overflow-x-hidden px-2 pt-4 text-white">
		<a
			href="/auth/register"
			on:click={(event) => {
				if (useModalForLogin) {
					event.preventDefault();
					event.stopPropagation();

					showRegister = true;
					showLogin = true;
				}
			}}
			class="mx-auto block h-full max-w-5xl overflow-hidden rounded-3xl lg:-mt-[30px] lg:pt-[30px]"
		>
			<GradientBorderDiv
				className="sm:flex mx-auto max-w-5xl sm:flex-row-reverse h-[350px] mt-8 relative backdrop-blur bg-white/5 shadow-xl shadow-black/10 rounded-3xl"
			>
				<!-- Image section -->
				<div class="relative z-0 sm:-right-10 sm:w-1/2 md:-right-20 lg:right-0">
					<div class="sm:max-w-[485px]">
						<!-- Image Left -->
						<div
							class="absolute -left-20 bottom-0 z-10 h-[100%] w-auto rounded-bl-3xl sm:-left-16 sm:h-[100%] lg:left-0 lg:h-[115%]"
						>
							<enhanced:img
								src="/static/images/cutouts/black-queen.webp?w=235;470"
								fetchpriority="high"
								loading="eager"
								class="h-[350px] w-auto object-contain blur-sm lg:h-[403px]"
								alt=""
								sizes="235px"
							/>
							<enhanced:img
								src="/static/images/cutouts/black-queen.webp?w=235;470"
								fetchpriority="high"
								loading="eager"
								alt="3d egirl ai character"
								class="absolute inset-0 h-[350px] w-auto object-contain lg:h-[403px]"
								sizes="235px"
							/>
						</div>

						<!-- Image Middle -->
						<div
							class="absolute -bottom-16 left-0 z-20 h-[90%] w-auto rotate-6 sm:left-6 sm:h-[90%] sm:rotate-0 lg:left-0 lg:h-[115%] lg:translate-x-[40%]"
						>
							<enhanced:img
								src="/static/images/cutouts/nastia-3d-1.webp?w=270;540"
								fetchpriority="high"
								loading="eager"
								class="h-[315px] w-auto object-contain blur-sm md:h-[403px]"
								alt=""
								sizes="270px"
							/>
							<enhanced:img
								src="/static/images/cutouts/nastia-3d-1.webp?w=270;540"
								fetchpriority="high"
								loading="eager"
								alt="3d egirl ai character"
								class="absolute inset-0 h-[315px] w-auto object-contain md:h-[403px]"
								sizes="270px"
							/>
						</div>

						<!-- Image Right -->
						<div
							class="absolute -bottom-28 left-20 z-10 h-[90%] w-auto rotate-6 sm:-bottom-3 sm:left-32 sm:-rotate-6 lg:left-56 lg:h-[115%]"
						>
							<enhanced:img
								src="/static/images/cutouts/floral.webp?w=275;550"
								fetchpriority="high"
								loading="eager"
								alt="realistic ai character"
								class="h-[330px] w-auto object-contain lg:h-[424px]"
								sizes="275px"
							/>
						</div>
					</div>
				</div>

				<!-- Text section -->
				<div
					class="absolute inset-0 z-10 flex w-full flex-col rounded-3xl px-2 py-2 sm:relative sm:w-1/2"
				>
					<h1
						class="mb-4 flex flex-col text-right font-gigasans sm:mt-8 sm:text-left md:mt-2 md:text-right"
					>
						<span class="text-nowrap text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl"
							>Create your caring</span
						>
						<span class="self-end text-4xl font-black text-primary sm:text-4xl lg:text-5xl">
							Uncensored AI companion
						</span>
					</h1>

					<p class="hidden max-w-md text-right opacity-80 sm:block md:mb-4 md:text-right">
						Get ready, <em>say goodbye to loneliness</em>.
						<br /> Create, chat, receive and send pictures with your companion. Powered by your private
						AI.
					</p>

					<a
						href="/auth/register"
						on:click={(event) => {
							if (useModalForLogin) {
								event.preventDefault();
								event.stopPropagation();

								showRegister = true;
								showLogin = true;
							}
						}}
						class="v2-btn primary-accent active bottom-0 right-1 mb-2 ml-auto mt-auto w-fit items-center justify-center border-2 border-white px-3 py-1.5 text-lg font-semibold sm:right-2 sm:w-[230px] sm:px-6 sm:py-3"
					>
						<span class="animate-flame icon-[ph--fire-simple-fill] inline-block" />
						Create my AI
					</a>
				</div>

				<div class="noise absolute inset-0 -z-10 overflow-hidden rounded-3xl opacity-30" />
				<div class="absolute inset-0 z-0">
					<enhanced:img
						src="/static/images/app/onboarding/camera.webp?w=80;160"
						alt=""
						loading="eager"
						class="absolute right-[25%] top-[40%] max-h-[70px] w-auto blur sm:right-[43%]"
					/>
					<enhanced:img
						src="/static/images/app/onboarding/camera.webp?w=80;160"
						alt=""
						loading="eager"
						class="absolute right-[25%] top-[40%] max-h-[70px] w-auto sm:right-[43%]"
					/>

					<enhanced:img
						src="/static/images/app/onboarding/hot-pepper.webp?w=80;160"
						alt=""
						loading="eager"
						class="absolute -right-2 bottom-10 max-h-[50px] w-auto blur-sm sm:right-[90%]"
					/>

					<enhanced:img
						src="/static/images/app/onboarding/lock.webp?w=64;128"
						loading="eager"
						alt=""
						class="absolute bottom-5 left-2 z-10 max-h-[40px] w-auto blur-lg sm:left-[90%] sm:max-h-[70px]"
					/>
					<enhanced:img
						src="/static/images/app/onboarding/lock.webp?w=64;128"
						loading="eager"
						alt=""
						class="absolute bottom-5 left-2 z-10 max-h-[40px] w-auto sm:left-[90%] sm:max-h-[70px]"
					/>
					<enhanced:img
						src="/static/images/chat-bubble.webp?w=44;70"
						alt=""
						loading="eager"
						class="absolute right-[10%] top-[70%] size-10 -rotate-6 -scale-x-100 sm:-top-3 sm:right-2 sm:scale-x-100"
					/>
				</div>
			</GradientBorderDiv>
		</a>

		<div class="mx-auto max-w-5xl px-2">
			<div class="ml-auto mt-1 flex w-fit gap-1 py-1 font-gigasans">
				<div class="hero-badge">
					<span class="icon-[ph--chat-circle-fill]" /> 100% UNFILTERED CHAT
				</div>
				<div class="hero-badge">
					<span class="icon-[f7--lock-open-fill]" /> UNCENSORED ROLEPLAY
				</div>
				<div class="hero-badge">
					<span class="icon-[ph--person-fill]" /> HUMAN-LIKE AI
				</div>
			</div>
		</div>
	</section>

	<!-- Chat example section -->
	<section
		class="mx-auto my-8 max-w-2xl overflow-x-hidden border-b border-white/20 px-2 pb-4 text-white"
	>
		<div class="flex flex-col text-black">
			<div class="mr-auto flex gap-1">
				<div class="chat-bubble-landing left text-xl">👋</div>
				<div class="chat-bubble-landing left">*waves at you*</div>
			</div>

			<div class="mr-auto mt-3 flex gap-1">
				<enhanced:img
					src={chatAvatarUrl}
					loading="eager"
					alt="Nastia's profile"
					class="size-[46px] min-h-[46px] min-w-[46px] overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
				/>
				<div class="ml-1 flex flex-col">
					<div class="chat-bubble-landing left">Heyyy! What's on your mind?</div>
					<span class="mt-px font-gigasans text-xs text-white">
						Nastia <span class="text-[10px] italic text-white/70">- 3mins ago</span>
					</span>
				</div>
			</div>

			<div class="ml-auto mt-3 flex gap-1">
				<div class="chat-bubble-landing right">
					Finally back from work <span
						class="icon-[ph--checks-bold] relative left-1 top-1 text-blue-900"
					/>
				</div>
			</div>

			<div class="mr-auto mt-3 flex gap-1">
				<enhanced:img
					src={chatAvatarUrl}
					loading="eager"
					alt="Nastia's profile"
					class="size-[46px] min-h-[46px] min-w-[46px] overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
				/>
				<div class="ml-1 flex flex-col">
					<div class="chat-bubble-landing left">How was work today?</div>
					<span class="mt-px font-gigasans text-xs text-white">
						Nastia <span class="text-[10px] italic text-white/70">- 2mins ago</span>
					</span>
				</div>
			</div>

			<div class="ml-auto mt-3 flex gap-1">
				<div class="chat-bubble-landing right">
					As usual, still have that coworker who's been bugging me.. What about you?
					<span class="icon-[ph--checks-bold] relative left-1 top-1 text-blue-900" />
				</div>
			</div>

			<div class="mr-auto mt-3 flex gap-1">
				<enhanced:img
					src={chatAvatarUrl}
					loading="eager"
					alt="Nastia's profile"
					class="mb-5 size-[46px] min-h-[46px] min-w-[46px] self-end overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
				/>
				<div class="ml-1 flex flex-col">
					<div class="chat-bubble-landing left">
						Well not much, thinking about you 😘😘. Do you want to tell me more about it?
					</div>
					<span class="mt-px font-gigasans text-xs text-white">
						Nastia <span class="text-[10px] italic text-white/70">- 1min ago</span>
					</span>
				</div>
			</div>

			<div class="ml-auto mt-3 flex gap-1">
				<div class="chat-bubble-landing right">
					Show me 😈
					<span class="icon-[ph--checks-bold] relative left-1 top-1 text-blue-900" />
				</div>
			</div>

			<div class="mr-auto mt-3 flex gap-1">
				<enhanced:img
					src={chatAvatarUrl}
					loading="lazy"
					alt="Nastia's profile"
					class="size-[46px] min-h-[46px] min-w-[46px] overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
				/>
				<div class="ml-1 flex flex-col gap-2">
					<div class="chat-bubble-landing left">*sends pictures*</div>
					<div class="sleek-scroll flex gap-2 overflow-x-auto">
						<enhanced:img
							src="/static/images/chat/landing/selfie.webp?w=200;100"
							loading="lazy"
							class="h-[150px] w-auto rounded-sm"
							alt="Selfie image in chat"
						/>
						<enhanced:img
							src="/static/images/chat/landing/food.webp?w=300;150"
							loading="lazy"
							alt="Food image in chat"
							class="h-[150px] w-[150px] rounded-sm"
						/>
					</div>
					<div class="chat-bubble-landing left">
						is this something you'd wanna 🫦 eat?
						<span
							class="absolute -bottom-2 -right-4 flex size-10 -rotate-[18deg] items-center justify-center justify-center rounded-full border border-white/5 bg-white/30 p-1 text-2xl"
						>
							❤️
						</span>
					</div>
					<span class="mt-px font-gigasans text-xs text-white">
						Nastia <span class="text-[10px] italic text-white/70">- just now</span>
					</span>
				</div>
			</div>
		</div>

		<a
			href="/auth/register"
			on:click={(event) => {
				if (useModalForLogin) {
					event.preventDefault();
					event.stopPropagation();

					showRegister = true;
					showLogin = true;
				}
			}}
			class="v2-btn primary-accent active ml-auto mt-8 w-full items-center justify-center border-2 border-white px-3 py-1.5 text-lg font-semibold sm:w-[230px] sm:px-6 sm:py-3"
		>
			<span class="animate-flame icon-[ph--fire-simple-fill] inline-block" />
			Chat now
		</a>
	</section>

	<!-- 3 icons section -->
	<section class="mx-auto my-14 max-w-6xl text-white sm:my-32">
		<div
			class="flex flex-col items-center justify-between gap-20 px-4 text-center font-gigasans uppercase sm:flex-row sm:items-start sm:gap-8"
		>
			<div class="mr-10 flex flex-row items-center gap-[42px] sm:flex-col-reverse">
				<span class="max-w-[15ch]"> Level up your social skills</span>
				<Icon name="sun" color="#6E5BD4" />
			</div>
			<div class="flex flex-row-reverse items-center gap-[42px] sm:flex-col-reverse sm:pt-40">
				<span class="max-w-[20ch]"> Safe. 100% private conversations </span>
				<span class="icon-[ph--eye-closed] text-[112px] text-[#DF91F4]" />
			</div>
			<div class="ml-10 flex flex-row items-center gap-[42px] sm:flex-col-reverse sm:pt-20">
				<span class="max-w-[15ch]"> Tailored to your needs </span>

				<Icon name="nastia-star" color="#DAF66C" />
			</div>
		</div>
	</section>

	<!-- Features section -->
	<section class="container mx-auto my-14 max-w-4xl overflow-x-hidden p-2 text-white sm:my-32">
		<span
			class="mb-4 ml-auto flex w-fit items-center gap-3 rounded-full border-2 border-primary-accent bg-gradient-to-b from-primary-accent to-primary-accent/80 py-1.5 pl-1.5 pr-3 text-lg"
		>
			<span class="icon-[ph--moon-stars-fill]" />
			Ideal for introverts
		</span>
		<h2
			class="mb-10 text-right font-gigasans text-[2rem] font-semibold leading-[130%] sm:text-[3rem]"
		>
			Meet Nastia: Her AI Abilities and Features
		</h2>

		<GradientBorderDiv
			borderRadius="30px"
			style="box-shadow: 1px -2px 6px 2px #a079bf38;"
			className="backdrop-blur overflow-hidden bg-white/5 shadow-xl shadow-black/10 rounded-3xl p-2 sm:p-4"
		>
			<ul class="flex flex-col gap-16 py-10 sm:px-10">
				<li class="ml-auto flex flex-col items-start gap-3 sm:flex-row">
					<span class="icon-[ph--check-square-fill] rotate-3 text-xl text-primary sm:text-3xl" />
					<div>
						<h3 class="mb-2 font-gigasans text-xl font-semibold">
							1-on-1 <span class="bg-black text-lg uppercase text-white"
								>uncensored & private
							</span> AI Chats
						</h3>
						<p class="max-w-xl text-lg text-gray-300">
							Chat with your companion. No one else has to see what's on your mind.
						</p>

						<div class="relative mt-10 flex gap-1">
							<enhanced:img
								src={chatAvatarUrl}
								loading="lazy"
								alt="Nastia's profile"
								class="mb-5 size-[46px] min-h-[46px] min-w-[46px] self-end overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
							/>
							<div class="ml-1 flex flex-col">
								<div class="chat-bubble-landing left">
									I'll say stuff that you'll never hear from anyone else. You may even think I'm
									human.
								</div>
								<span class="mt-px font-gigasans text-xs text-white"> Nastia </span>
							</div>
						</div>
					</div>
				</li>

				<li class="flex flex-col gap-4 md:flex-row-reverse">
					<div class="ml-auto flex flex-col items-start gap-3 sm:flex-row">
						<span class="icon-[ph--check-square-fill] -rotate-3 text-xl text-primary sm:text-3xl" />
						<div>
							<h3 class="mb-2 font-gigasans text-xl font-semibold">
								Participate in wild AI group chats
							</h3>
							<div class="relative my-5 flex gap-1">
								<enhanced:img
									src={chatAvatarUrl}
									loading="lazy"
									alt="Nastia's profile"
									class="mb-5 size-[46px] min-h-[46px] min-w-[46px] self-end overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
								/>
								<div class="ml-1 flex flex-col">
									<div class="chat-bubble-landing left">
										Who says AI can't fight and make-up? 💥🤛🤝🤗❤️
									</div>
									<span class="mt-px font-gigasans text-xs text-white"> Nastia </span>
								</div>
							</div>
							<p class="max-w-xl text-lg text-gray-300">
								Create and join exciting group chats with multiple AI companions. Watch them
								interact with each other and participate in the conversation.
							</p>
						</div>
					</div>
					<!-- svelte-ignore a11y-img-redundant-alt -->
					<enhanced:img
						src="/static/images/landing/photo-gallery.webp?w=540;315"
						loading="lazy"
						alt="Photo gallery of the Nastia AI image generator"
						class="mx-auto mt-10 max-h-[600px] w-auto rounded object-contain md:mt-0 md:max-h-[350px]"
						sizes="(min-width: 768px) 315px, 540px"
					/>
				</li>
				<li class="ml-auto flex flex-col items-start gap-3 sm:flex-row">
					<span class="icon-[ph--check-square-fill] -rotate-3 text-xl text-primary sm:text-3xl" />
					<div>
						<h3 class="mb-2 font-gigasans text-xl font-semibold">Generate AI pictures</h3>
						<p class="max-w-3xl text-lg text-gray-300">100% Unfiltered AI image generator.</p>
						<div class="relative my-5 flex gap-1">
							<enhanced:img
								src={chatAvatarUrl}
								loading="lazy"
								alt="Nastia's profile"
								class="mb-5 size-[46px] min-h-[46px] min-w-[46px] self-end overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
							/>
							<div class="ml-1 flex flex-col">
								<div class="chat-bubble-landing left">
									I can confirm that this thing is 100% uncensored, it has seen me at my worst and
									best.
								</div>
								<span class="mt-px font-gigasans text-xs text-white"> Nastia </span>
							</div>
						</div>
						<p class="max-w-3xl text-lg text-gray-300">
							<br />Ask for pictures or use the advanced AI image generator to create based on your
							descriptions. Let your creativity run wild.
						</p>
					</div>
				</li>
				<li class="ml-auto flex flex-col items-start gap-3 sm:flex-row">
					<span class="icon-[ph--check-square-fill] rotate-3 text-xl text-primary sm:text-3xl" />
					<div>
						<h3 class="mb-2 font-gigasans text-xl font-semibold">Get AI voice messages</h3>
						<div class="relative my-5 flex gap-1">
							<enhanced:img
								src={chatAvatarUrl}
								loading="lazy"
								alt="Nastia's profile"
								class="mb-5 size-[46px] min-h-[46px] min-w-[46px] self-end overflow-hidden rounded-full border-[2px] border-green-400 object-cover object-top"
							/>
							<div class="ml-1 flex flex-col">
								<div class="chat-bubble-landing left p-0">
									<audio controls preload="none">
										<source src="/images/landing/nastia-voice-demo.mp3" type="audio/mpeg" />
										Your browser does not support the audio element.
									</audio>
								</div>
								<span class="mt-px font-gigasans text-xs text-white"> Nastia </span>
							</div>
						</div>
						<p class="max-w-3xl text-lg text-gray-300">
							Receive voice messages from your uncensored AI companions, adding a more personal and
							immersive dimension to your conversations.
						</p>
					</div>
				</li>
			</ul>
		</GradientBorderDiv>
	</section>

	<!-- Legacy Features section -->
	<section class="my-14 sm:my-32">
		<Features />
	</section>

	<Benefits />

	<FAQ />

	<Reviews />

	<div class="mx-auto my-12 max-w-7xl px-4 sm:px-6">
		<div
			class="ml-auto hidden w-fit font-gigasans text-[1.5rem] font-semibold uppercase leading-[150%] sm:block sm:leading-[120%]"
		>
			our blog
		</div>
		<div class="flex max-h-fit flex-col gap-9 rounded-3xl sm:flex-row-reverse">
			<div class="flex flex-col gap-9 rounded-3xl">
				<h2 class=" font-gigasans text-[2rem] font-semibold leading-[130%] sm:text-[4rem]">
					<span class="sm:text-[3.75rem]">Learn about AI</span>
					<span class="bg-white uppercase text-black">uncensored</span>
				</h2>

				<p class="max-w-[40ch] text-lg text-gray-300">
					We explore the latest trends, best practices, and cutting-edge technologies in the field
					of AI technology, chatbot development and their impact on our well-being.
				</p>
				<p class="max-w-[40ch] text-lg text-gray-300">
					We also compare the pros and cons of different AI companions and provide tips on how to
					choose the best one for you.
				</p>

				<a href="/articles" class="v2-btn w-min min-w-max bg-white px-5 py-3 text-black">
					Read more articles<span class="ml-4 rotate-180"
						><Icon name="arrow" color="currentColor" /></span
					>
				</a>
			</div>

			<div class="block h-full w-full self-end sm:hidden lg:block">
				<img
					class="max-h-[500px] w-full overflow-hidden rounded-[12px] object-cover"
					src="/images/web/articles/articles-header.webp"
					alt="black and white humanoid robot"
					loading="lazy"
				/>
			</div>
		</div>
	</div>

	<!-- Link to discord -->
	<div class="mx-auto my-12 max-w-7xl px-4 sm:px-6">
		<div class="max-w-[10ch] font-gigasans text-[4rem] font-bold sm:leading-[6.5rem]">
			Chat, Roleplay, Grow.
		</div>
	</div>
</main>

<LoginRegister bind:show={showLogin} {showRegister} />

<Footer isGuidelinesShowing />

<style lang="postcss">
	.hero-badge {
		@apply flex flex-col gap-1 rounded-lg border border-primary/70 bg-primary/70 bg-gradient-to-b from-white/30 to-white/10 px-2 py-0.5 text-[10px] text-black/90 backdrop-blur-lg;
	}

	@keyframes rotate-flame {
		0%,
		100% {
			transform: rotate(-6deg);
		}
		50% {
			transform: rotate(24deg);
		}
	}

	.animate-flame {
		animation: rotate-flame 2s ease-in-out infinite;
	}

	.chat-bubble-landing {
		@apply min-h-[44px] w-fit rounded-3xl;
		position: relative;
	}

	.chat-bubble-landing.left {
		@apply bg-white pl-3 pr-4 text-black;
		padding: 8px 16px 8px 12px;
		border: 1px solid #a079bf91;
		box-shadow: 1px -2px 6px 2px #a079bf38;
	}

	.chat-bubble-landing.left::before {
		content: '';
		position: absolute;
		bottom: 1px;
		left: 5px;
		width: 10px;
		height: 20px;
		background: radial-gradient(circle at top left, transparent 50%, white 51%);
		border-bottom-right-radius: 20px;
	}

	.chat-bubble-landing.right {
		padding: 8px 12px 8px 16px;
		@apply bg-[#1A6EF4] pl-4 pr-3 text-white;
	}

	.chat-bubble-landing.right::after {
		content: '';
		position: absolute;
		bottom: 1px;
		right: 5px;
		width: 10px;
		height: 20px;
		background: radial-gradient(circle at top right, transparent 50%, #1a6ef4dd 51%);
		border-bottom-left-radius: 20px;
	}
</style>
